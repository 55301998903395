<script lang="ts" setup>
import { ref } from "vue"
import { useRouter, useRoute } from "vue-router"
import { getChildById } from "@/services/Child"
import {
  createOrReplaceGrowthAssessment,
  getGrowthAssessmentForChild,
} from "@/services/GrowthAssessment"
import {
  Plausibility,
  sanityCheckMeasurementValue,
} from "@/utils/GrowthAssessment"
import { GrowthAssessment } from "@/models/GrowthAssessment"
import ChildHeader from "@/components/children/ChildHeader.vue"
import PageLoading from "@/components/PageLoading.vue"
import ModalWindow from "@/components/ModalWindow.vue"
import { gettext } from "@/utils/Translation"

const { $gettext } = gettext
const router = useRouter()
const route = useRoute()
const childId = parseInt(route.params.childId as string)
const assessmentId = parseInt(route.params.assessmentId as string)
const child = ref(null)
const growthAssessment = ref(null)
const requireDoubleCheck = ref(false)
const doubleCheckMsg = ref(null)
const formReady = ref(false)
const showModal = ref(false)
const props = defineProps({
  getSchemaFunc: {
    type: Function,
    required: true,
  },
  extraGetDataFunc: {
    type: Function,
    default: null,
  },
  extractValueFunc: {
    type: Function,
    required: true,
  },
  getMeasurementTypeFunc: {
    type: Function,
    required: true,
  },
  getUpdateDataFunc: {
    type: Function,
    required: true,
  },
})

async function getData() {
  child.value = await getChildById(childId)
  if (assessmentId) {
    const assessmentData = await getGrowthAssessmentForChild(assessmentId)
    growthAssessment.value = new GrowthAssessment(child.value, assessmentData)
  }
  else {
    growthAssessment.value = await getGrowthAssessmentForChild(assessmentId, child.value)
  }
  if (props.extraGetDataFunc) {
    props.extraGetDataFunc(growthAssessment.value)
  }
  formReady.value = true
}

async function submitForm(data) {
  const value = props.extractValueFunc(data)
  // If the value wasn't supplied OR if the user attests to the measurement having been double-checked,
  // then skip the following sanity checking logic and just submit the form.
  if (value && !data.valueIsDoubleChecked) {
    const sanityCheck = await sanityCheckMeasurementValue(growthAssessment.value, value, props.getMeasurementTypeFunc())
    if (sanityCheck !== Plausibility.Plausible) {
      const msg = $gettext("Based on an earlier measurement for this child, this value is surprising. Please re-measure.")
      const baselineMsg = $gettext("Based on this child's age, this value is surprising. Please re-measure.")
      doubleCheckMsg.value = sanityCheck === Plausibility.UnlikelyAtBaseline ? baselineMsg : msg
      requireDoubleCheck.value = true
      return
    }
  }
  const fields = props.getUpdateDataFunc(data)
  Object.entries(fields).forEach(([key, prop]) => growthAssessment.value[key] = prop)
  createOrReplaceGrowthAssessment(child.value, growthAssessment.value)
  router.push({ name: "MeasurementsRequired", params: route.params })
}

getData()
</script>

<template>
  <article>
    <ChildHeader
      v-if="child"
      :child="child"
    />

    <ModalWindow
      :show-modal="showModal"
      :close-modal-function="() => showModal = false"
    >
      <slot name="modalContent" />
    </ModalWindow>

    <h1 class="title">
      {{ $gettext("Growth") }}
    </h1>
    <template v-if="formReady">
      <a
        class="text-link"
        @click="showModal = true"
      >
        {{ $gettext("Review technique") }}
      </a>
      <FormKit
        type="form"
        :submit-label="$gettext('Save')"
        :submit-attrs="{ inputClass: 'button is-primary' }"
        @submit="submitForm"
      >
        <p
          v-if="doubleCheckMsg"
          class="message double-check-warning"
        >
          {{ doubleCheckMsg }}
        </p>
        <FormKitSchema
          :schema="getSchemaFunc(growthAssessment)"
          :data="growthAssessment"
        />
        <FormKit
          v-if="requireDoubleCheck"
          id="valueIsDoubleChecked"
          type="checkbox"
          :label="$gettext('I have double-checked this measurement.')"
          name="valueIsDoubleChecked"
          :value="false"
          outer-class="single-checkbox loud"
          validation="accepted"
          :validation-messages="{ accepted: $gettext('This field is required.') }"
        />
      </FormKit>

      <slot name="exampleImage" />
    </template>
    <PageLoading v-else />
  </article>
</template>
